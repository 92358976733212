@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-responsive-carousel/lib/styles/carousel.min.css";

body {
  @apply font-poppins;
}

 /* Splash Screen */
 .splash-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #000;
  }
  .splash-screen img {
    width: 200px;
  }
  
  /* VR Experience */
  .vr-experience {
    width: 100%;
    height: 100%;
  }

  .carousel-item {
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-image {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease-in-out;
  }
  
  .active-slide .carousel-image {
    transform: scale(1.1); /* Scale up the active slide */
  }
  
  .carousel-item:not(.active-slide) .carousel-image {
    transform: scale(0.85); /* Scale down non-active slides */
  }
  
  .carousel .control-arrow:hover {
    background: transparent !important; /* Remove hover background */
    }
  
  