.plot {
    cursor: pointer;
    stroke: black;
    stroke-width: 1px;
  }
  
  .plot.available {
    fill: gray;
  }
  
  .plot.booked {
    fill: red;
    cursor: not-allowed;
  }
  
  .plot.selected {
    fill: green;
  }
